/* tslint:disable */
import * as React from 'react';
import { useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import * as ScreenStreams from '../surveyCollector/screenStreams';
import {Choice, SingleChoiceProps} from './ElementTypeInterfaces';
import SingleChoice from './SingleChoice';
import striptags from 'striptags';
import { useGlobalState } from '../GlobalState';
import StarIcon from '@material-ui/icons/Star';
import StarOutlinedIcon from "@material-ui/icons/StarBorder";
import BottomModal from './BottomModal';

export interface GoogleReviewProps {
  text: string;
  reviewId: string;
  instruction: string;
  googleSecondPhase: string;
  nextButtonOverride(func: Function): void;
  autoNextEnabled: boolean;
  googleYesButton: string;
  googleNoButton: string;
  googleCopyInstruction: string;
  googleModalMessage: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  squareButton: {
    borderRadius: 0,
    margin: `${theme.spacing()}px 0`,
    textTransform: 'none',
  },
  root: {
    width: '100%',
    maxWidth: 800,
  },
  instruction: {
    fontSize: '0.85rem',
    textAlign: 'left',
    margin: '8px auto 8px 0px',

    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const GoogleReview: React.FunctionComponent<
  GoogleReviewProps & SingleChoiceProps
> = (props) => {
  const styles = useStyles();
  const [selectedOption, setSelectedOption] = useState(0);
  const [nextScreen, setNextScreen] = useState(false);
  const [reviewShown, setReviewShown] = useState(false);

  const popupWindow = (
    url: string,
    windowName: string,
    win: Window & typeof globalThis,
    w: number,
    h: number
  ) => {
    if (!win.top) {
      return null;
    }
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  };

  const goToReview = () => {
    if (popupWindow(reviewLink(), 'Review', window, 630, 600) == null) {
      return <p>Window popup failed!</p>;
    } else {
      ScreenStreams.onNextClick();
    }
    setReviewShown(true);
    return null;
  };

  const localOnChange = (pickedValue: any) => {
    setSelectedOption(pickedValue);
    props.onChange(pickedValue);

    if (props.autoNextEnabled) {
      if (pickedValue != 999) {
        componentOnNextClick();
      } else {
        ScreenStreams.onNextClick();
      }
    }
  };

  const { globalState } = useGlobalState();

  const copyText = async (input: string) => {
    try {
      await navigator.clipboard.writeText(input);
      //console.log("copy good");
    } catch (err) {
      console.log("Copy err" + err );
    }
  };
  const copyOnChange = (pickedValue: any) => {
    if (pickedValue != 999){
    copyText(globalState.rating_text).then(handleOpenModal);
    }
  };

  const componentOnNextClick = () => {
    setNextScreen(true);
  };

  const returnFuncToParent = () => {
    if (nextScreen) {
      return ScreenStreams.onNextClick;
    }
    if (selectedOption != 999) {
      return componentOnNextClick;
    }
    return ScreenStreams.onNextClick;
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const page1 = () => {
    const choiceValues = props.choices;
    const choices: Choice[] = [
      { value: 1, text: props.googleYesButton ? props.googleYesButton : "Yes, copy"},
      { value: 999, text: props.googleNoButton ?  props.googleNoButton : "No, thanks" }
    ];
    return (
      <div className={styles.root}>
        <SingleChoice
          choices={choiceValues}
          onChange={localOnChange}
          value={selectedOption}
        />
        <div style={{
          borderTop: 'solid rgba(125, 125, 125, .5)',
          borderBottom: 'solid rgba(125, 125, 125, .5)',
          marginBottom: '5px',
          display: 'flex', flexDirection: 'column', alignItems: 'flex-start'
        }}>
          <p>{props.googleCopyInstruction ? props.googleCopyInstruction : 'Here is a reminder of your review'} </p>
          <div>
            {Array.from({ length: globalState.rating_star }, (_, index) => (
            <StarIcon key={`A-${index}`} className="element element-a">Element A</StarIcon>))}
            {Array.from({ length: (5 - globalState.rating_star) }, (_, index) => (
              <StarOutlinedIcon key={`A-${index}`} className="element element-a">Element A</StarOutlinedIcon>))}
          </div>

          <p>{globalState.rating_text}</p>
          <SingleChoice
            choices={choices}
            onChange={copyOnChange}
            value={selectedOption}
          />
          <BottomModal
            isOpen={isModalOpen}
            text={props.googleModalMessage ? props.googleModalMessage : "Text was successfully copied"}
            onClose={handleCloseModal}
          />
        </div>
      </div>
    );
  };

  const reviewLink = () => {
    return `https://g.page/r/${props.reviewId}/review`;
  };

  const secondPhaseText = () => {
    if (!props.googleSecondPhase) {
      return <></>;
    }
    const match = props.googleSecondPhase.match(/\$\[([^)]+)\]/);
    const anchorText = match && match[1];
    if (!anchorText) {
      return <></>;
    }
    const screenText = props.googleSecondPhase.split(`\$\[${anchorText}\]`);
    return (
      <>
        <p>
          {screenText[0]}
          <a href={reviewLink()} target="_blank">
            {anchorText}
          </a>
          {screenText[1]}
        </p>
      </>
    );
  };

  const page2 = () => {
    return (
      <>
        {!reviewShown && goToReview()}
        {secondPhaseText()}
      </>
    );
  };

  React.useEffect(() => {
    props.nextButtonOverride(returnFuncToParent);
  }, [selectedOption, nextScreen]);

  return (
    <>
      {props.reviewId === null ? ScreenStreams.onNextClick() : () => { return; }}
      {props.instruction && (
        <Typography variant="body1" className={styles.instruction}>
          {striptags(props.instruction)}
        </Typography>
      )}
      <div className={styles.root}>
        {!nextScreen && page1()}
        {nextScreen && page2()}
      </div>
    </>
  );
};

export default GoogleReview;
