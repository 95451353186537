import React, { useEffect } from 'react';

const BottomModal = ({ isOpen, text, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 10000); // 10 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when isOpen changes
    }
    return () => {};
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const styles: { [key: string]: React.CSSProperties } = {
    modalOverlay: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100px', // Adjust height as needed
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.0)', // Semi-transparent background
    },
    modalContent: {
      background: '#78faae',
      padding: '20px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    modalBody: {
      display: 'flex',
      alignItems: 'center',
    },
    checkMark: {
      fontSize: '24px',
      color: 'green',
      marginRight: '10px',
    },
    paragraph: {
      margin: 0,
    },
  };

  return (
    <div style={styles.modalOverlay} onClick={onClose}>
      <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div style={styles.modalBody}>
          <span style={styles.checkMark}>✔</span>
          <p style={styles.paragraph}>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default BottomModal;
