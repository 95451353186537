import 'url-polyfill';
import { merge, pipe, tap } from 'ramda';
import { init, jumpToEnd } from './screenStreams';
import {
  Config,
  LoadingErrorResponse,
  LoadingResponse,
  LoadingStandardResponse,
  SurveyParams,
} from './SurveyCollector';
import {
  displayConnectionError,
  getLastTransition,
  goToFillInBlocked,
  goToLoading,
  StateType,
} from '../uiState/states';
import { getFirstBrowserLanguage } from './languageDetection';
import { mergeDefinitionAndTranslations } from 'skoda-xperience-model-management';

import { cleanSurveyData, loadSurveyData } from './localStore';
import { sendData } from './surveySubmitting';
import {
  acquireParamsFromUrl,
  createSurveyUrl,
  UrlSurveyParams,
} from './surveyUrl';
import { surveyPreProcessing } from '../surveyModel/preProcessing';
import {
  createSurveyMetadata,
  getCommonTranslations,
} from '../surveyModel/getters';

const processLoadedSurvey = (config: Config, surveyParams: SurveyParams) => (
  response: LoadingResponse
): void => {
  let reason;

  switch (response.surveyStatus) {
    case 'NEW':
    case 'IN_PROGRESS':
      const processedSurvey = surveyPreProcessing(response.survey);

      init(
        config,
        surveyParams,
        mergeDefinitionAndTranslations(processedSurvey),
        response.sampleData,
        response.collectedData || {},
        response.events || [],
        getCommonTranslations(processedSurvey),
        createSurveyMetadata(response, processedSurvey),
        response.brand
      );
      break;

    case 'FINISHED':
      reason = response.userMessage || 'Survey finished.';
      goToFillInBlocked(reason);
      break;

    case 'EXPIRED':
      reason = response.userMessage || 'Survey expired.';
      goToFillInBlocked(reason);
      break;

    case 'OPT_OUT':
      reason = response.userMessage || 'Survey opt out.';
      goToFillInBlocked(reason);
      break;

    default:
      goToFillInBlocked('Unknown error');
      break;
  }
};

const processLoadingError = (response: LoadingErrorResponse): void => {
  console.error(response);

  goToFillInBlocked('Survey loading failed', response.status);
};

const loadSurveyAsynchronously = (config: Config) => ({
  surveyParams,
  searchParams,
}) => {
  fetch(createSurveyUrl(config.REACT_APP_API_URI, surveyParams, searchParams), {
    method: 'GET',
    headers: {
      'Accept-Language': getFirstBrowserLanguage(),
      Accept: 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response;
      }

      throw response;
    })
    .then((response) => response.json())
    .then(mergeWithSavedData(config, surveyParams))
    .then(processLoadedSurvey(config, surveyParams))
    .catch(processLoadingError);
};

export const loadSurveyByLocation = (config: Config, currentUrl: string) => {
  // TODO better error processing
  try {
    return pipe<string, string, UrlSurveyParams, void>(
      tap((v) => goToLoading()),
      acquireParamsFromUrl,
      loadSurveyAsynchronously(config)
    )(currentUrl);
  } catch (e) {
    console.error(e);
    goToFillInBlocked('Unknown error');
  }
};

export const mergeWithSavedData = (
  config: Config,
  surveyParams: SurveyParams
) => (response: LoadingStandardResponse) => {
  const savedSurveyData = loadSurveyData(surveyParams);
  const surveyMetaData = createSurveyMetadata(response, savedSurveyData);

  if (savedSurveyData.surveyStatus === 'FINISHED') {
    sendData(config, surveyParams, surveyMetaData, savedSurveyData)
      .then((surveyData) => {
        const lastTransition = getLastTransition();

        if (
          savedSurveyData.surveyStatus === 'FINISHED' &&
          lastTransition.type === StateType.ConnectionErrorDisplayed
        ) {
          jumpToEnd('offlineRecovery');
        }
      })
      .catch((surveyData) => {
        if (savedSurveyData.surveyStatus === 'FINISHED') {
          cleanSurveyData(surveyParams);
          displayConnectionError();
        }
      });
  }

  return merge(response, savedSurveyData) as LoadingStandardResponse;
};
