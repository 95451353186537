import React, { createContext, useContext, useState, Dispatch, SetStateAction, ReactNode } from 'react';

// Define the shape of your global state
interface GlobalState {
  rating_text: string;
  rating_star: number;
  locked: boolean;

}

// Define the context value type
interface GlobalContextType {
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

// Create a context with a default value (null)
const GlobalContext = createContext<GlobalContextType | null>(null);

// Custom hook to use the global state
export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};



// Provider component to wrap your application
export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [globalState, setGlobalState] = useState<GlobalState>({ rating_text: 'empty for now', rating_star: 3, locked: false});

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
